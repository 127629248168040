import * as React from 'react'
export function PlayBtn({ title, height = 54, width = 54, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
      <title>{title}</title>
      <g filter="url(#a)">
        <ellipse cx={27} cy={25.178} fill="#fff" fillOpacity={0.5} rx={20} ry={19.452} />
        <path
          stroke="#fff"
          strokeWidth={1.5}
          d="M46.25 25.178c0 10.31-8.599 18.702-19.25 18.702S7.75 35.487 7.75 25.178c0-10.31 8.599-18.702 19.25-18.702s19.25 8.393 19.25 18.702Z"
        />
      </g>
      <path fill="#fff" d="m37 25.173-15 8.423V16.75l15 8.423Z" />
      <defs>
        <filter
          id="a"
          width={54}
          height={52.904}
          x={0}
          y={0.726}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology in="SourceAlpha" radius={2} result="effect1_dropShadow_5965_16102" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend
            in2="BackgroundImageFix"
            mode="multiply"
            result="effect1_dropShadow_5965_16102"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology in="SourceAlpha" radius={2} result="effect2_dropShadow_5965_16102" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.09 0" />
          <feBlend
            in2="effect1_dropShadow_5965_16102"
            mode="multiply"
            result="effect2_dropShadow_5965_16102"
          />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_5965_16102" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export default PlayBtn
