/**
 * Mappings for Contentful uiComponent fields
 *
 * The purpose of these constants are to provide documentation around reusable contentful content
 * types that have multiple `uiComponent` options. Currently, some components have the `uiComponent`
 * field but the code doesn't always leverage them. But for the purposes where we do want to use
 * that field, we should provide constants that match their names in Contentful `uiComponent` field.
 */
export const LINK_UI_COMPONENTS = {
  LINK: 'Link',
  LINK_BUTTON: 'LinkButton',
  LINK_TO_SECTION: 'LinkToSection',
  LINK_AUTH0: 'LinkAuth0',
}
